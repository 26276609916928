<template>
  <div class="job-responsibilities-items">
    <JobResponsibilitiesItem
      v-for="responsibility in responsibilities"
      :key="responsibility.id"
      :responsibility="responsibility"
    />
  </div>
</template>

<script>
const JobResponsibilitiesItem = () =>
  import(
    "@/components/ProfileComponents/JobFolderComponents/JobResponsibilities/JobResponsibilitiesItem"
  );

export default {
  name: "JobResponsibilitiesItems",
  mixins: [],
  props: {
    responsibilities: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  components: { JobResponsibilitiesItem },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/breakpoints/breakpoints";

.job-responsibilities-items {
  display: flex;
  flex-direction: column;
}
</style>
